<template>
  <div class="password-reset">
    <a href="/dashboard">
      <img
        style="width: 150px"
        src="../../assets/logo-exim.jpg" />
    </a>

    <form v-if="!showSuccess" @submit.prevent>
      <h4>Recover your password</h4>
      <div class="field">
        <a-input
          v-model.trim="email"
          type="email"
          size="large"
          placeholder="email" />
          <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
      </div>
      <a-button
        type="primary"
        size="large"
        @click="resetPassword()" block>Reset password</a-button>
    </form>
    <p v-else>
    Reset password link sent to your email
    <router-link to='/login'>back to login</router-link>
    </p>
  </div>
</template>

<script>
import { auth } from '@/utils/firebase';

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = '';

      try {
        await auth.sendPasswordResetEmail(this.email);
        this.showSuccess = true;
      } catch (error) {
        this.errorMsg = error.message
      }
    }
  }
}
</script>

<style lang="less">
.password-reset {
  margin: 0 auto;
  max-width: 300px;
  padding-top: 25%;

  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
  }

  p {
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  .field {
    margin: 1rem 0;
  }

  .error {
    color: red;
  }
}
</style>
